.feedList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  overflow: scroll;
}

.feedCard {
  width: 100%;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  gap: 24px;
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 70%;
  text-align: justify;
}
.summary{
  font-size: 16px;
  font-weight: 400;
  color: #475467;
  white-space: pre-line;
  margin-top: 18px;
}
.sectionTitle{
  font-size: 17px;
  font-weight: 700;
  color: rgb(16, 24, 40);
}
.boldText{
  font-weight: 600;
  color: rgb(16, 24, 40);
}
.filters {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.filterItem {
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 4px 16px;
}

.filterItem .title {
  color: #5e5adb;
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
  text-transform: capitalize;
}