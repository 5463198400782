.accordion{
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}
.expanded{
  box-shadow: 0px 0px 0px 2px rgba(16, 24, 40, 0.08);
}
.header{
  display: flex;
  gap: 8px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  color: #344054;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  flex-shrink: 0;
  flex-grow: 0;
}

.disabled{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
}

.content{
  background-color: #FFF;
  color: #101828;
  height: 0px;
  transition:  0.2s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: content-box;
}

.show{
  padding: 12px;
  height: auto;
  border: 1px solid rgba(208, 213, 221, 0.50);
}
.icon{
  color: #344054;
  font-size: 12px;
  transition: transform 0.2s ease-in-out;
}
.rotate{
  transform: rotate(90deg);
}

.tableStyle {
  width: 100%;
  font-family: 'Roboto Mono' !important
}

.tableStyle th {
  background: #FFF !important;
  padding: 8px !important;
}

.tableStyle th::before {
  display: none;
}

.tableStyle td {
  color: #1C1C1C !important;
  border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05)) !important;
  padding: 8px !important;
}

.tableStyle tbody tr:last-child td {
  border: none !important;
}

.tableStyle tbody tr:hover td {
  background-color: #fff !important;
}

.tableStyle table {
  overflow-x: scroll;
}
