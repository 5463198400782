.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.contentSection {
  display: flex;
  gap: 24px;
  height: 100%;
  overflow: hidden;
}

.report {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  width: 70%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  overflow: scroll;
  height: 100%;
}

.report::-webkit-scrollbar {
  display: none;
}

.graphContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: fit-content;
  min-width: 50%;
  align-self: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 32px;
}

.chatBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  overflow: scroll;
}

.chatBox::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.messages {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 12px;
}

.self {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  background-color: #5E5ADB;
  padding: 10px 14px;
  border-radius: 8px 0px 8px 8px;
  color: #fff;
  width: fit-content;
}

.bot {
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 400;
  color: #101828;
  background-color: #F9FAFB;
  border-radius: 0px 8px 8px 8px;
  white-space: pre-line;
  margin-left: 12px;
  width: 100%;
}

.inputContainer {
  display: flex;
  align-items: center;
  height: 60px;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  padding: 12px 16px;
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
  flex-grow: 1;
}

.emptyChat {
  display: flex;
  flex-direction: column;
  width: fit-content;
  color: #120F43;
  margin: auto;
  height: 100%;
}

.emptyChatHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  align-self: center;
  /* width: 100%; */
  background-color: #F2F3FF;
  border-radius: 8px;
  padding: 6px 14px 6px 8px;
  font-weight: 600;
}

.quesSec {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.examples {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 8px;
}

.sampleQues {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.sampleQues:hover {
  border-color: #5D5ADA;
  color: #5D5ADA;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.emptyChat img {
  margin-bottom: -66px;
  margin-right: -24px;
  margin-left: -38px;
}