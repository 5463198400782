.container{
  background: url(../../assets/ferrous-blurred.jpg) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.content{
  background: url(../../assets/clients.png) no-repeat;
  background-size: cover;
}
.modal-wrap{
  z-index: 1 !important;
}
.ant-modal-mask{
  display: none;
}
.modalContent{
  padding: 0px;
  margin: auto;
}
.modalContent .ant-modal-content{
  padding: 0px 0px 24px 0px;
  border-radius: 12px;
}

.content p {
  padding-top: 125px;
  font-size: 18px;
  color: #101828;
  font-weight: 600;
  text-align: center;
  margin: 0;

  margin-bottom: 4px;
}
.content span{
  color: #475467;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.buttonContainer{
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: center;
}
.basicButton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #D0D5DD;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  min-width: 150px;
}

.primaryButton{
  background-color: #5E5ADB;
  color: #fff;
}

.modalContent .buttonContainer .basicButton:hover{
  border-color:#D0D5DD;
  color: #344054;
  transform: translateY(-2px);
}
.modalContent .buttonContainer .primaryButton:hover{
  color: #fff;
  transform: translateY(-2px);
}
