.optionBtn {
    border-radius: 8px;
    border: 1px solid rgba(94, 90, 219, 0.15);
    background: rgba(94, 90, 219, 0.01);
    color: #5E5ADB;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 16px;
}

.optionBtn:hover {
    background: rgba(94, 90, 219, 0.08);
}

.queryResult {
    display: flex;
    width: 720px !important;
    min-height: 460px !important;
    padding: 17.405px 23.207px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17.405px;
    border-radius: 11.604px;
    border: 0.725px solid var(--Colors-black-alpha-10, rgba(18, 18, 18, 0.10));
    background: var(--Colors-white-white, #FFF);
}

.divider {
    margin: 0;
    width: 100%;
}

.slider {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: fit-content;
    border-radius: 8px;
}

.sliderOption {
    color: #344054;
    padding: 10px 0;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    padding: 8px 16px;
    border-right: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    border-top: 1px solid #D0D5DD;
    /* border-left: 1px solid var(--Colors-Border-border-primary, #D0D5DD); */
}

.sliderOption:first-child {
    border-left: 1px solid #D0D5DD;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.sliderOption:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.sliderSelectedOption {
    background-color: rgb(233 237 245);
}


.resultFeedback {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

.feedbackBtn {
    display: flex;
    padding: 5.077px;
    justify-content: center;
    align-items: center;
    gap: 5.077px;
    border-radius: 5.077px;
    background: var(--Colors-grey-50, #FAFAFA);
    box-shadow: 0px 0.635px 1.904px 0px rgba(18, 18, 18, 0.10), 0px 0px 0px 0.635px rgba(18, 18, 18, 0.10), 0px 0.635px 0.635px 0px rgba(18, 18, 18, 0.20);
}

.feedbackBtnActive {
    background-color: #5E5ADB;
    color: white;
    display: flex;
    padding: 5.077px;
    justify-content: center;
    align-items: center;
    gap: 5.077px;
    border-radius: 5.077px;
    border-color: #5E5ADB;
}

.feedbackBtnActive:hover {
    color: white !important;
    outline: none !important;
    border-color: #5E5ADB !important;
}

.tableStyle {
    width: 100%;
    font-family: 'Roboto Mono' !important
}

.tableStyle th {
    background: #FFF !important;
    padding: 8px !important;

}

.tableStyle th::before {
    display: none;
}


.tableStyle td {
    color: #1C1C1C !important;
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05)) !important;
    padding: 8px !important;
}

.tableStyle tr:first-child td {
    padding: 0 !important;
}

.tableStyle tbody tr:last-child td {
    border: none !important;
}

.tableStyle tbody tr:hover td {
    background-color: #fff !important;
}

.tableStyle table {
    overflow-x: scroll;
}

.sqlbox {
    width: 100%;
    height: 200px;
    padding: 10px;
    resize: none;
    border: 0.725px solid var(--Colors-black-alpha-10, rgba(18, 18, 18, 0.10));
    border-radius: 6px;
    margin-top: 12px;
}

.textheading {
    color: var(--Component-colors-Components-Buttons-Tertiary-color-button-tertiary-color-fg, #6941C6);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 6px;
}

.normaltext {
    color: var(--colors-text-text-tertiary-600, #475467);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.summary {
    color: var(--colors-text-text-tertiary-600, #475467);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

}

.sampleQuestion {
    display: flex;
    width: 280px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-none, 0px);
    border-radius: 8px;
    background: var(--Primary-Light, #F7F9FB);
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    cursor: pointer;
    /* 14 Regular */
    font-size: 14px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 20px;

    /* 142.857% */
}

.sampleQuestion:hover {
    background-color: #E9EDF5;
}

.exampleRadioButton label::before {
    display: none !important;
    background-color: #D0D5DD !important;
}

.exampleRadioButton label div {
    display: none !important;
}